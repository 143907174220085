<template>
  <div class="content">
    <h1 class="title title--big title--theme title--indent">Добавление ФМС</h1>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group label-for=name label="Наименование" required>
            <app-input
                v-model.trim="form.name"
                id="name"
                placeholder="Введите наименование ФМС"
                :class="{ 'input--error': $v.form.name.$error }"
            />
            <template #error>
              <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label-for=code label="Код" required>
            <app-input
                v-model.trim="form.code"
                v-mask="'999-999'"
                id="code"
                placeholder="Введите код ФМС"
                :class="{ 'input--error': $v.form.code.$error }"
            />
            <template #error>
              <div v-if="$v.form.code.$dirty && !$v.form.code.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button :disabled="$v.form.$error" ref="submit">Добавить ФМС</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CreateFMS',
  data() {
    return {
      form: {},
    }
  },
  validations: {
    form: {
      name: { required },
      code: { required },
    }
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('admin/fms/POST_DATA', this.form)
        .then(() => {
          this.$refs.submit.preload = false
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'ФМС добавлена'
          })
          this.$router.push({ name: 'fms-list' })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            this.$notify({
              type: 'error',
              title: 'Внимание!',
              text: error.response.data[key][0]
            })
          }
        })
    },
  }
}
</script>
